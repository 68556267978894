* {
  cursor: none;
}

body {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
